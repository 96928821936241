import React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../../components/Layout"
import Grid from "../../components/Grid"
import Button from "../../components/Button"
import SignupLink from "../../components/SignupLink"
import { FaArrowRight } from "react-icons/fa"

import fontType from "../../helpers/fontType.module.scss"
import css from "./index.module.scss"

function CaseStudyTemplate({ data: { mdx } }) {
  const metadata = {
    title: mdx.frontmatter.metaTitle,
    description: mdx.frontmatter.description,
    image: mdx.frontmatter.facebookImage,
  }
  return (
    <Layout metadata={metadata} pathname={mdx.fields.slug}>
      <Grid className={css.container}>
        <p className={fontType.b1}>
          {mdx.frontmatter.enterprise
            ? "CASE STUDY: ENTERPRISE CUSTOMER"
            : "CASE STUDY"}
        </p>
        <h1>{mdx.frontmatter.title}</h1>
      </Grid>
      <MDXRenderer
        showcase={mdx.frontmatter.testimonialShowcase}
        avatar={mdx.frontmatter.testimonialAvatar}
      >
        {mdx.body}
      </MDXRenderer>
      <Grid className={css.container} centeredClassName={css.centered}>
        <Button el={Link} to="/case-studies">
          Read Another Case Study
        </Button>
      </Grid>
      <Grid className={css.ctaContainer} centeredClassName={css.centered}>
        <div className={css.text}>
          <h2>Start your own Referral Program</h2>
          <p>
            Join {mdx.frontmatter.retailer} and other fast-growing brands to
            reward your most engaged customers
          </p>
          <SignupLink el={Button} className={css.button} purpose="primary">
            Try Free for 30 Days &nbsp; <FaArrowRight />
          </SignupLink>
          <p className={fontType.b4}>No contract. Cancel anytime</p>
        </div>
      </Grid>
    </Layout>
  )
}

export default CaseStudyTemplate

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
        metaTitle
        description
        facebookImage
        retailer
        testimonialShowcase {
          childImageSharp {
            fixed(width: 305) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        testimonialAvatar {
          childImageSharp {
            fixed(width: 80) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        enterprise
      }
      body
    }
  }
`
